import React from "react";
import {Box, Grid, Hidden, Typography} from "@mui/material";
import {graphql} from "gatsby";
import PCEmailSVG from "assets/images/pc_email.svg";
import SiteLayout from "components/layouts/SiteLayout";
import Section from "components/molecules/Section";

export default function ThankYouScreen() {
  return (
    <SiteLayout>
      <Section padding={2}>
        <Box sx={{display: {xs: "none", md: "block"}, p: 10}}>
          <Grid container justifyContent="center">
            <Grid item container xs={12} md={6} alignContent="center">
              <Grid item md={12}>
                <Typography variant="h1" color="primary">Dziękujemy!</Typography>
                <Typography variant="h1" color="primary">Został już tylko...</Typography>
              </Grid>
              <Grid item md={12}>
                <Typography variant="h4">
                  ...ostatni krok: na podany adres wysłaliśmy wiadomość z wypełnioną{" "}
                  <i>deklaracją NFZ</i>{" "}i instrukcją co z nią zrobić. Jest to prosty
                  dokument, dzięki któremu możemy zaoferować Ci opiekę lekarza bez
                  dodatkowych opłat, w ramach NFZ.
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={6}
              alignContent="center"
              justifyContent="center"
            >
              <PCEmailSVG/>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{display: {xs: "block", md: "none"}}}>
          <Grid container justifyContent="center">
            <Hidden smUp>
              <Grid
                item
                container
                xs={12}
                md={6}
                alignContent="center"
                justifyContent="center"
                spacing={2}
              >
                <Grid item md={12} justifyContent="center">
                  <Typography variant="h1" align="center" color="primary">
                    Dziękujemy!
                  </Typography>
                  <Typography variant="h2" align="center">
                    Jeszcze tylko...
                  </Typography>
                </Grid>
                <Grid item md={12}>
                  <Typography align="center">
                    ...odbierz maila, na którego wysłaliśmy gotowy formularz NFZ wraz
                    z instrukcją postępowania.
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={6}
                alignContent="center"
                justifyContent="center"
                sx={{ mt: 6 }}
              >
                <PCEmailSVG height="200px" />
              </Grid>
            </Hidden>
          </Grid>
        </Box>
      </Section>
    </SiteLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
